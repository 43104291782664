<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-back-button color="primary"></ion-back-button>
        </ion-buttons>
        <ion-title>Dettaglio Contratto</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>   
        </ion-toolbar>
      </ion-header>

      <div id="container">
        <div>
          <br>
          <label>Stato contratto:</label>
          <span v-if="contract.stato==1">ATTIVO</span>
          <span v-if="contract.stato==2">SOSPESO</span>
          <span v-if="contract.stato==3">DISATTIVO</span>
          <br>
          <ion-item v-if="$store.state.userRole>4">
            <ion-button color="success" @click="attiva()">
              ATTIVA
            </ion-button> 
            <ion-button color="warning" @click="sospendi()">
              SOSPENDI
            </ion-button>
            <ion-button color="danger" @click="disattiva()">
              DISATTIVA
            </ion-button>
          </ion-item>
          <br>
        </div>  
        <br><br>
        <dtable
          color="#fff"
          bgcolor="rgb(56,128,255)"
          :heading="tasksHeading"
          :data="tasks"
          :total="table.total"
          :perPage="table.perPage"
          :currentPage="table.currentPage"
          :orderBy="table.orderBy"
          :order="table.order"
          @changePage="getContractInfo"
        >        
          <template v-slot:consultant="slotProps">
              {{slotProps.row.consultant_name}}
          </template>
          <template v-slot:account="slotProps">
              {{slotProps.row.account_name}}
          </template>
          <template v-slot:btns="slotProps">
            <ion-button @click="$router.push('/folder/dettaglio-attivita/'+slotProps.row.id);">
                <ion-icon name="search-outline"></ion-icon>
            </ion-button>
          </template>
        </dtable>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
  import { 
    IonBackButton,
    IonButton,
    IonButtons, 
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonMenuButton, 
    IonPage,
    IonTitle, 
    IonToolbar,
    toastController
  } from '@ionic/vue';
  import Dtable from './partials/Dtable'
  import {pencilOutline, trashOutline} from 'ionicons/icons';

  export default {
    name: 'Folder',
    components: {
          IonBackButton,
          IonButton,
          IonButtons, 
          IonContent, 
          IonHeader,
          IonIcon,
          IonItem,
          IonMenuButton, 
          IonPage,
          IonTitle, 
          IonToolbar,
          Dtable,
    },

    data: function(){
      return{
        pencilOutline:pencilOutline,
        trashOutline:trashOutline,
        contract:{
          ref_off:'',
          stato:''
        },
        tasks: [],
        tasksHeading: [
          {
            text: 'Rif. Offerta',
            code: 'ref_off'
          },
          {
            text: 'Risorsa',
            code: 'consultant'
          },
          {
            text: 'Data Inizio',
            code: 'begindate'
          },
          {
            text: 'Data Fine',
            code: 'enddate'
          },
          {
            text: 'Cliente',
            code: 'account'
          },
          {
            text: 'Descrizione',
            code: 'description'
          },
          {
            text: '',
            code: 'btns'
          }
        ],
        table: {
          currentPage: 1,
          total: '',
          perPage: 0,
          orderBy: 'begindate',
          order: 'desc',
        },
      }
    },
    methods:{
      getContractInfo(obj = false){
        if(obj) {
          this.table.currentPage = obj.page
          this.table.orderBy = obj.orderBy
          this.table.order = obj.order
        }
        this.axios.post(this.apiUrl + 'get-contract-info', {id:this.$route.params.id, ref_off:this.$route.params.ref_off, page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order}, this.headers)
        .then(async (response) => {
          this.tasks=response.data.tasks.data;
          this.table.total = response.data.tasks.total
          this.table.perPage = response.data.tasks.per_page
        })
      },
      getContract(){
        this.axios.post(this.apiUrl + 'get-contract-info', {id:this.$route.params.id, ref_off:this.$route.params.ref_off}, this.headers)
        .then(async (response) => {
          this.contract = response.data.contract
        })
      },
      attiva(){
        this.axios.post(this.apiUrl + 'aggiorna-contract', {ref_off:this.$route.params.ref_off, stato:1}, this.headers)
          .then(async (response) => {
            if(response.data.status_code==200){
                const toast = await toastController
                .create({
                    color: 'success',
                    message: response.data.message,
                    duration: 2000
                })
                this.$router.push('/folder/report-contratti');
                return toast.present();
            }
          })
        },
        sospendi(){
          this.axios.post(this.apiUrl + 'aggiorna-contract', {ref_off:this.$route.params.ref_off,stato:2}, this.headers)
          .then(async (response) => {
            if(response.data.status_code==200){
              const toast = await toastController
              .create({
                  color: 'success',
                  message: response.data.message,
                  duration: 2000
                })
              this.$router.push('/folder/report-contratti');
              return toast.present();
            }
          })
        },
        disattiva(){
          this.axios.post(this.apiUrl + 'aggiorna-contract', {ref_off:this.$route.params.ref_off,stato:3}, this.headers)
          .then(async (response) => {
            if(response.data.status_code==200){
              const toast = await toastController
              .create({
                color: 'success',
                message: response.data.message,
                duration: 2000
              })
              this.$router.push('/folder/report-contratti');
              return toast.present();
            }
          })
        }
      },
      ionViewWillEnter: function(){
        this.getContractInfo();
        this.getContract();
      }
    }
</script>
<style scoped>
  #container{
    width:90%;
    margin:5%; 
  }
</style>